import PropTypes from 'prop-types';
import {Slot} from '@radix-ui/react-slot';

/**
- Should only be used to create composite Icon Components e.g. IcArrowDown
- when creating a new icon you should add it to icons/index
- when using the icons you should be importing from the index
* */
export default function Icon({label, className, children}) {
  return (
    <Slot className={className} aria-label={label}>
      {children}
    </Slot>
  );
}

Icon.propTypes = {
  /**
  This will be set as the aria-label and is useful for automated tests
  */
  label: PropTypes.string,
  /**
  This will be set as the css class on the svg passed in as children
  */
  className: PropTypes.string,
  /**
  Should always be an svg that follows the following
  - svg fill property set to currentColor
  - paths with fillRule property set to evenodd
  - paths with no fill property set
  */
  children: PropTypes.node.isRequired,
};
Icon.defaultProps = {
  className: '',
  label: 'icon',
};
