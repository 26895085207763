import Icon from './Icon';

const HAMBURGER = (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 12C2 11.1716 2.74619 10.5 3.66667 10.5H20.3333C21.2538 10.5 22 11.1716 22 12C22 12.8284 21.2538 13.5 20.3333 13.5H3.66667C2.74619 13.5 2 12.8284 2 12Z" />
    <path d="M2 18.5C2 17.6716 2.74619 17 3.66667 17H20.3333C21.2538 17 22 17.6716 22 18.5C22 19.3284 21.2538 20 20.3333 20H3.66667C2.74619 20 2 19.3284 2 18.5Z" />
    <path d="M2 5.5C2 4.67157 2.74619 4 3.66667 4H20.3333C21.2538 4 22 4.67157 22 5.5C22 6.32843 21.2538 7 20.3333 7H3.66667C2.74619 7 2 6.32843 2 5.5Z" />
  </svg>
);

export default function IcMenuHamburger({className}) {
  return (
    <Icon className={className} label="Hamburger">
      {HAMBURGER}
    </Icon>
  );
}

IcMenuHamburger.propTypes = {
  className: Icon.propTypes.className,
};

IcMenuHamburger.defaultProps = {
  className: '',
};
